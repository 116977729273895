import '../styles/App.css';
import React from "react";
import Form from 'react-bootstrap/Form'
import axios from "axios";

class SearchAddress extends React.Component {

    getAddress() {
        let query = document.getElementById("searchAddressBarInput").value;
        const resultDiv = document.getElementById("searchResults");
        if (query.length > 0) {
            axios.get('https://api-adresse.data.gouv.fr/search/?q='+query)
                .then((response) => {
                    resultDiv.innerHTML = ''
                    if (response.data.features.length > 0) {
                        resultDiv.style.display = "block";
                        for (let i = 0; i < response.data.features.length; i++) {
                            let element = document.getElementById("searchResults");
                            element.innerHTML += '<p class="searchResultElement" data-lat="'+response.data.features[i].geometry.coordinates[1]+'" data-lng="'+response.data.features[i].geometry.coordinates[0]+'">'+response.data.features[i].properties.label+'</p>';
                        }
                    } else {
                        resultDiv.style.display = "none"
                    }
                })
        } else {
            resultDiv.style.display = "none"
        }
    }

    render() {
        return(
            <Form className="formSearchAddressBar mt-2">
                <Form.Control className="searchAddressBar" name="address" type="text" placeholder="Entrez une adresse ici" id="searchAddressBarInput" onChange={this.getAddress} />
                <div className="searchAddressResults" id="searchResults">

                </div>
            </Form>
        )
    }
}

export default SearchAddress;