import '../styles/App.css';
import React from "react";
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { sendNewBarData } from '../api/request.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

class AddNewBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            address: "",
            priceN: 0,
            priceHH: 0,
            beginHH: "",
            endHH: "",
        }
    };

    updateForm = (event) => {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value
        });
    }

    //TODO : Handle error (si data != 200 alors montrer que y'a eu une erreur)
    async sendAndVerifForm() {
        let data = await sendNewBarData(this.state.name, this.state.address, this.state.priceN, this.state.priceHH, this.state.beginHH, this.state.endHH)
        this.props.handleNewBarWindow(false)

    }

    handleNewBarWindow() {
        this.props.handleNewBarWindow(false)
    }

    render() {
        return (
            <div className="AddNewBar">
                <Card className="anbCard">
                    <div className="biChevronDiv" onClick={this.handleNewBarWindow.bind(this)}>
                        <FontAwesomeIcon className="biIcon " size='1x' icon={faChevronDown} />
                    </div>
                    <Card.Body>
                        <Card.Title className="anbTitle">Ajouter un nouveau bar</Card.Title>
                        <Form style={{ height: '100%' }}>
                            <Form.Group className="anbFormGroup" controlId="">
                                <Form.Label>Nom</Form.Label>
                                <Form.Control name="name" type="text" placeholder="Au Bar Incroyable" onChange={this.updateForm} />
                            </Form.Group>

                            <Form.Group className="anbFormGroup" controlId="">
                                <Form.Label>Adresse</Form.Label>
                                <Form.Control name="address" type="text" placeholder="82 Rue de Paris 75019 Paris" onChange={this.updateForm} />
                            </Form.Group>

                            <Form.Group className="anbFormGroup" controlId="">
                                <div className="addNewBarPriceDiv">
                                    <div className="anbSubDiv">
                                        <Form.Label>Prix normal</Form.Label>
                                        <Form.Control name="priceN" type="number" min="0" placeholder="6" onChange={this.updateForm} />
                                    </div>
                                    <div className="anbSubDiv">
                                        <Form.Label>Prix Happy Hour</Form.Label>
                                        <Form.Control name="priceHH" type="number" min="0" placeholder="3.5" onChange={this.updateForm} />
                                    </div>
                                </div>
                            </Form.Group>

                            <Form.Group className="anbFormGroup" controlId="">
                                <div className="addNewBarPriceDiv">
                                    <div className="anbSubDiv">
                                        <Form.Label>Début Happy-Hour</Form.Label>
                                        <Form.Control name="beginHH" type="text" placeholder="17" onChange={this.updateForm} />
                                    </div>
                                    <div className="anbSubDiv">
                                        <Form.Label>Fin Happy-Hour</Form.Label>
                                        <Form.Control name="endHH" type="text" placeholder="21" onChange={this.updateForm} />
                                    </div>
                                </div>
                            </Form.Group>

                            <Button className="anbFormBtn" onClick={this.sendAndVerifForm.bind(this)} >
                                Ajouter
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </div>
        );
    }
}

export default AddNewBar;
