import '../styles/App.css';
import Map from '../components/Map';
 
function App() {
  return (
    <div className="App">
      <Map />
      
    </div>
  );
}

export default App;
