import '../styles/App.css';
import { getAllBars, getBarInfos } from '../api/request.js';
import React from "react";
import { MapContainer, TileLayer, Marker, Popup, MapConsumer } from 'react-leaflet'
import AddNewBar from './AddNewBar';
import BarInfos from './BarInfos';
import LocationMarker from './LocationMarker';
import addBtn from '../assets/add.svg'
import recenterBtn from '../assets/recenter.svg'
import * as L from "leaflet";
import SearchAddress from "./SearchAddress";

class Map extends React.Component {
    constructor(props) {
        super(props);
        this.mapRef = React.createRef();
        this.state = {
            barList: [],
            addBarWindow: false,
            barInfosWindow: false,
            barInfos: {}
        };
    }

    async componentDidMount() {
        var barList = await getAllBars()
        this.setState({ barList: barList })
        console.log(barList)
    }

    openAddBarWindow() {
        this.setState({ addBarWindow: true })
    }

    recenterMap() {
        this.mapRef.current.locate({ setView: true });
    }

    handleNewBarWindow = async (childData) => {
        this.setState({ addBarWindow: childData })
        var barList = await getAllBars()
        this.setState({ barList: barList })
    }

    handleBarInfos = (childData) => {
        this.setState({ barInfosWindow: childData })
    }

    async showBarInfos(barId, barLocation) {
        var barInfos = await getBarInfos(barId)
        this.setState({ barInfosWindow: true })
        this.setState({ barInfos: barInfos })
        this.mapRef.current.setView(barLocation, 15)
    }
    
    //TODO : Calcul dans le back
    isHH(beginHH, endHH) {
        const d = new Date();
        let current_hour = d.getHours();
        return current_hour >= beginHH && current_hour < endHH;
    }

    render() {
        let markers = []
        if (this.state.barList) {
            var markerClass, price;
            this.state.barList.forEach(element => {
                markerClass = this.isHH(element.beginHH, element.endHH) ? 'markerBarHH' : 'markerBar'
                price = this.isHH(element.beginHH, element.endHH) ? element.priceHH : element.priceN
                markers.push(<Marker
                    key={element._id}
                    icon={L.divIcon({
                        className: markerClass,
                        html: price,
                        iconSize: [25, 41],
                        iconAnchor: [10, 44],
                        popupAnchor: [3, -40],
                    })}
                    eventHandlers={{
                        // CALLING 2 TIMES THE FUNCTION ONCLICK 
                        click: (e) => { this.showBarInfos(element._id, e.target.getLatLng()) }
                    }}
                    position={[element.lat, element.lng]}>
                </Marker>)
            })
        }

        return (
            <div className="App">
                <MapContainer
                    className="map"
                    center={[48.854, 2.347]}
                    zoom={13}
                    scrollWheelZoom={true}
                    whenCreated={mapInstance => { this.mapRef.current = mapInstance; mapInstance.locate({ setView: true, maxZoom: 16 }); }}
                    zoomControl={false}>

                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                        url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}{r}.png"
                        subdomains='abcd'
                        maxZoom={20}
                        minZoom={6}
                    />

                    <LocationMarker />
                    {markers ? markers : console.log("Problème dans le chargement des bars")}
                </MapContainer>

                <SearchAddress />

                <div className="addBarBtn" onClick={this.openAddBarWindow.bind(this)}>
                    <img
                        src={addBtn}
                        alt="add"
                        width="60px"
                        height="60px"
                    />
                </div>

                <div className="recenterBtn" onClick={this.recenterMap.bind(this)}>
                    <img
                        src={recenterBtn}
                        alt="add"
                        width="60px"
                        height="60px"
                    />
                </div>

                {
                    this.state.addBarWindow ? <AddNewBar handleNewBarWindow={this.handleNewBarWindow} /> : null
                }

                {
                    this.state.barInfosWindow ? <BarInfos handleBarInfos={this.handleBarInfos} barInfos={this.state.barInfos} /> : null
                }
            </div>
        );
    }
}

export default Map;
