import axios from 'axios';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

const BACK_LOCAL = process.env.REACT_APP_BACK_URL;

export function getAllBars() {
    return new Promise(resolve => {
        axios.get(BACK_LOCAL+'/api/bar/list')
            .then(res => {
                if (res.status = 200) {
                    resolve(res.data);
                }
            })
            .catch(error => {
                console.log(error.response.data.error)
                resolve(error.response.data.error);
            })
    })

}

export function sendNewBarData(name, address, priceN, priceHH, beginHH, endHH) {
    return new Promise(resolve => {
        axios.post(BACK_LOCAL+'/api/bar/add', { name, address ,priceN ,priceHH ,beginHH ,endHH })
            .then(res => {
                if (res.status = 200) {
                    resolve(res.data);
                }
            })
            .catch(error => {
                console.log(error.response.data.error)
                resolve(error.response.data.error);
            })
    })

}

export function getBarInfos(id) {
    return new Promise(resolve => {
        axios.get(BACK_LOCAL+'/api/bar/view/' + id)
            .then(res => {
                if (res.status = 200) {
                    resolve(res.data);
                }
            })
            .catch(error => {
                console.log(error.response.data.error)
                resolve(error.response.data.error);
            })
    })

}


