import '../styles/App.css';
import React from "react";
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faClock, faChevronDown } from '@fortawesome/free-solid-svg-icons'

class BarInfos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isHH:true // Remplacer this.state.isHH par this.props.barInfos.happyHourTime
        };
    }

    handleBarInfos(){
        this.props.handleBarInfos(false)
    }

    // TODO : Calcul dans le back
    isHH(beginHH, endHH) {
        const d = new Date();
        let current_hour = d.getHours();
        return current_hour >= beginHH && current_hour < endHH;
    }

    render() {
        return (
            <div className="BarInfos">
                <Card className="biCard">
                    <div className="biChevronDiv" onClick={this.handleBarInfos.bind(this)}>
                        <FontAwesomeIcon className="biIcon " size='1x' icon={faChevronDown} />
                    </div>
                    <Card.Body>
                        <Card.Title className="biTitle">{this.props.barInfos.name}</Card.Title>
                        <div className="biMainDiv mb-2">
                            <div className={(this.isHH(this.props.barInfos.beginHH, this.props.barInfos.endHH) ? 'biMetricDiv' : 'biMetricDivNormal')}> {/* PRIX NORMAL */}
                                <div className={(this.isHH(this.props.barInfos.beginHH, this.props.barInfos.endHH) ? 'biMetricValue' : 'biMetricValueNormal')}>{this.props.barInfos.priceN}</div>
                                <div>Normal</div>
                            </div>
                            <div className={(this.isHH(this.props.barInfos.beginHH, this.props.barInfos.endHH) ? 'biMetricDivHH' : 'biMetricDiv')}> {/* PRIX HAPPY HOUR */}
                                <div className={(this.isHH(this.props.barInfos.beginHH, this.props.barInfos.endHH) ? 'biMetricValueHH' : 'biMetricValue')}>{this.props.barInfos.priceHH}</div>
                                <div>Happy Hour</div>
                            </div>
                        </div>

                        <div className="biDiv">
                            <div className="biIconDiv">
                                <FontAwesomeIcon className="biIcon" size='2x' icon={faMapMarkerAlt} />
                            </div>
                            <div className="biInfosDiv">
                                <div className="biSubDiv">Adresse</div>
                                <div className="biSubDivValue">{this.props.barInfos.address}</div>
                            </div>
                        </div>

                        <div className="biDiv2">
                            <div className="biIconDiv">
                                <FontAwesomeIcon className="biIcon" size='2x' icon={faClock} />
                            </div>
                            <div className="biInfosDiv">
                                <div className="biSubDiv">Horaires Happy Hour</div>
                                <div className="biSubDivValue">{this.props.barInfos.beginHH}h - {this.props.barInfos.endHH}h </div>
                            </div>
                        </div>


                    </Card.Body>
                </Card>
            </div>
        );
    }
}

export default BarInfos;
