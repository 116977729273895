import '../styles/App.css';
import React, { useState, useEffect } from "react";
import { Marker, Popup, useMap, Circle } from 'react-leaflet'
import * as L from "leaflet";


const icon = new L.divIcon({
  className: '',
  iconSize: [22,22],
  popupAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  html: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150"><defs><style>.cls-1{fill:#fff;}.cls-2{fill:#40B01D;}</style></defs><title>currentLocation</title><g id="Calque_2" data-name="Calque 2"><g id="Calque_1-2" data-name="Calque 1"><circle class="cls-1" cx="75" cy="75" r="75"/><circle class="cls-2" cx="75" cy="75" r="60.13"/></g></g></svg>`
});



function LocationMarker(props) {
  const [position, setPosition] = useState(null);
  const map = useMap();
  // A chaque changement, il update la position
  useEffect(() => {
    map.locate().on("locationfound", function (e) {
      setPosition(e.latlng);
    });
  }, [map]);

  return position === null ? null : (
    <Marker position={position} icon={icon}>
      <Circle
        center={position}
        fillColor="#ABED45"
        color="#40B01D"
        weight={1}
        stroke={true}
        radius={100}
        fillOpacity={0.1}
        opacity={1} />
    </Marker>
  );
}

export default LocationMarker;